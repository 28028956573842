const routes = [
    {
        path: '/',
        name: 'HomePage',
        redirect: '/website/list',
        component: () => import('@/views/HomePage.vue'),
        children: [
            {
                path: '/website/list',
                name: 'WebsiteList',
                component: () => import('@/views/WebsiteList'),
            },
            {
                path: '/website/home',
                name: 'WebsiteHome',
                redirect: '/website/article/list',
                component: () => import('@/views/WebsiteHome'),
                children: [
                    {
                        path: '/website/article/list',
                        name: 'WebsiteArticleList',
                        component: () => import('@/views/Article/ArticleList'),
                    },
                    {
                        path: '/website/article/reptile',
                        name: 'WebsiteArticleReptile',
                        component: () => import('@/views/Article/ArticleReptile'),
                    },
                    {
                        path: '/website/article/detail',
                        name: 'WebsiteArticleDetail',
                        component: () => import('@/views/Article/ArticleDetail'),
                    },
                ]
            },
        ]
    }
]

export default routes